import { graphql } from "gatsby";
import * as React from "react";
import get from "lodash/get";

// Components
import Layout from "../layout";

// Sections
import {
  BlogListHero,
  BlogPosts,
  CanabisToAnja,
  ContactForm,
  FeaturedBlogPosts,
  GridCarousel,
  Hero,
  ImageBanner,
  ImageCarousel,
  ImageWithText,
  IngredientsCarousel,
  Newsletter,
  PartnersCarousel,
  PersonasCarousel,
  StoreLocation,
  Team,
  Bundles,
  TextHero,
  TextWithIconList,
  TextWithImageUnderlay,
  VisionMission,
  JobListings,
  MapWithText,
  PressPageHero,
  PressReleases,
  PrivacyPolicy,
  Test,
  FeaturedProductCarousel,
  ProductGrid,
  CannabisUser,
  WellnessGuide,
  AnjaPromise,
} from "../sections/standard";

const sections = {
  BlogListHero: BlogListHero,
  BlogPosts: BlogPosts,
  CanabisToAnja: CanabisToAnja,
  ContactForm: ContactForm,
  FeaturedBlogPosts: FeaturedBlogPosts,
  GridCarousel: GridCarousel,
  Hero: Hero,
  ImageBanner: ImageBanner,
  ImageCarousel: ImageCarousel,
  ImageWithText: ImageWithText,
  IngredientsCarousel: IngredientsCarousel,
  Newsletter: Newsletter,
  PartnersCarousel: PartnersCarousel,
  PersonasCarousel: PersonasCarousel,
  StoreLocation: StoreLocation,
  Team: Team,
  TextHero: TextHero,
  TextWithIconList: TextWithIconList,
  TextWithImageUnderlay: TextWithImageUnderlay,
  VisionMission: VisionMission,
  JobListings: JobListings,
  MapWithText: MapWithText,
  PressPageHero: PressPageHero,
  PressReleases: PressReleases,
  PrivacyPolicy: PrivacyPolicy,
  Test: Test,
  FeaturedProducts: FeaturedProductCarousel,
  ProductGrid: ProductGrid,
  BundleItem: Bundles,
  CannabisUser: CannabisUser,
  WellnessGuide: WellnessGuide,
};

class StandardPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulTemplateStandard");
    const home_page = () =>{
      return(
        page_data.sections.map((section) => {
        const Section = sections[section.internal.type.replace("ContentfulSection", "")];
        if(section.internal.type ==="ContentfulSectionFeaturedProducts"){
          if(section.heading.heading === "Home Page Carousel"){
            return(<>
              <FeaturedProductCarousel
                data={section}
              />
              <TextWithIconList/>
            </>
            )
          }
          else{
            return(
              <>
              <FeaturedProductCarousel
                data={section}
              />
              <StoreLocation/>
              </>
            )
          }
        }
        
        // else if(section.internal.type ==="ContentfulSectionBundles"){
        //   return(
        //     <>
        //     <Bundles
        //       data={section}
        //     />
        //     <StoreLocation/>
            
        //     </>
        //   )
        // }
        else if(section.internal.type ==="ContentfulSectionImageWithText" && section.ctaLabel === "Shop Now"){
          return(
            <>
            <ImageWithText
              data={section}
            />
            <CanabisToAnja/>
            <CannabisUser/>
            </>
          )
        }
        // else if(section.internal.type === "ContentfulKioskSectionBundleItem"){
        //   return (
        //     <>
        //     <Bundles data={section}/>
        //     {/* <StoreLocation/> */}
        //     </>
        //   )
        // }
        else{
          return (
          <Section
            data={section}
          />
        );
        }
      })
      )
    }

    const about_us_page = () =>{
      return(
        page_data.sections.map((section) => {
        const Section = sections[section.internal.type.replace("ContentfulSection", "")];
        if(section.internal.type ==="ContentfulSectionHero" &&
        section.heading.childMarkdownRemark.html === "<p>About Us</p>"
        ){
          return(<>
            <Hero
              data={section}
            />
            <ImageBanner data={section} title="About Us Image Banner"/>
          </>
          )
        }else if( section.internal.type === "ContentfulSectionImageCarousel"){
          return(<>
            <ImageCarousel
              data={section}
            />
            <AnjaPromise/>
          </>)
          
        }else{
          return (
          <Section
            data={section}
          />
        );
        }
      })
      )
    }

    const other_page = () =>{
      return(
        page_data.sections.map((section) => {
        const Section = sections[section.internal.type.replace("ContentfulSection", "")];
        
          return (
            <Section
              data={section}
            />
          );
        })
      )
    }

    const press_page = () => {
      return (
        page_data.sections.map((section) => {
          const Section = sections[section.internal.type.replace("ContentfulSection", "")];
          if(section.internal.type ==="ContentfulSectionFeaturedBlogPosts" &&
          section.heading?.heading === "Featured Release"
          ){
            return(<>
              <FeaturedBlogPosts
                data={section}
              />
              <Newsletter/>
              </>
            )
          }else{
            return (
              <Section
                data={section}
              />
            );
          }
        })
      )
    }

    const wellness = () => {
      return (
        page_data.sections.map((section) => {
          if(section.internal.type ==="ContentfulSectionHero"){
            return(
              <Hero data={section}/>
            )
          }else{
            return (
              <WellnessGuide data={section}/>
            )
          }
        })
      )
    }

    const load_page = (path) =>{
      if(path === "/"){
        return home_page();
      }else if(path === "/about-us"){
        return about_us_page();
      }else if(path === "/press-page"){
        return press_page();
      }else if(path === "/wellness"){
        return wellness();
      }else{
        return other_page();
      }
    }

    return (
      <Layout
        seo={{
          description: page_data.description ? page_data.description.description : "undefined",
          path: page_data.path,
          title: page_data.title,
        }}
      >
        {load_page(page_data.path)}
        
        
      </Layout>
    );
  }
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query StandardPageBypathQuery($path: String!) {
    contentfulTemplateStandard(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      sections {
        ... on Node {
          ... on ContentfulSectionHero {
            internal {
              type
            }
            type
            id
            backgroundColor
            backgroundImage {
              title
              url
            }
            heading {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              url
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            imageFit
            ctaLabel
            ctaLink
            ctaType
            ctaLabel2
            ctaLink2
            ctaType2
            bottomHeading
            bottomSubheading {
              bottomSubheading
            }
          }
          ... on ContentfulSectionFeaturedProducts {
            internal {
              type
            }
            id
            type
            heading {
              heading
            }
            products {
              subTitle
              title
              description {
                description
              }
              badge
              image {
                title
                gatsbyImageData(
                  layout: CONSTRAINED,
                  placeholder: BLURRED
                )
              }
              price
              ctaLabel
              ctaLink
            }
          }
          ... on ContentfulSectionImageWithText {
            internal {
              type
            }
            id
            type
            image {
              title
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            textColor
            backgroundColor
            imageLogo {
              title
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            richtext {
              raw
            }
            ctaLabel
            ctaLink
            imageAlignment
            idSectionName
          }
          ... on ContentfulSectionImageCarousel {
            internal {
              type
            }
            id
            images {
              title
              gatsbyImageData(
                layout: FULL_WIDTH,
                placeholder: BLURRED
              )
              url
            }
          }
          ... on ContentfulSectionPersonasCarousel {

            internal {
              type
            }
            id
            personas {
              title
              description {
                description
              }
              image {
                title
                gatsbyImageData(
                  layout: CONSTRAINED,
                  placeholder: BLURRED
                )
              }
              ctaLabel
              ctaLink

            }
          }
          ... on ContentfulSectionFeaturedBlogPosts {
            internal {
              type
            }
            id
            type
            heading {
              heading
            }
            featuredBlogPost {
              id
              title
              description {
                description
              }
              author
              image {
                title
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              date(formatString: "MMMM DD, YYYY")
              path
            }
            blogPosts {
              ... on ContentfulTemplateBlogPost {
                id
                title
                description {
                  description
                }
                author
                image {
                  title
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
                date(formatString: "MMMM DD, YYYY")
                path
              }
            }
          }
          ... on ContentfulSectionJobListings {
            internal {
              type
            }
            id
            title
            accordions {
              title
              richtext {
                raw
              }
              subAccordionTitle
              subAccordionRichText {
                raw
              }
            }
          }
        }
      }
    }
  }
`;

// ... on ContentfulSectionBundles {
//             internal {
//               type
//             }
//             id
//             header
//             body {
//               body
//             }
//             products {
//               subTitle
//               title
//               description {
//                 description
//               }
//               badge
//               image {
//                 title
//                 gatsbyImageData(
//                   layout: CONSTRAINED,
//                   placeholder: BLURRED
//                 )
//               }
//               price
//               ctaLabel
//               type
//               ctaLink
//             }
//           }

// ... on ContentfulKioskSectionBundleItem {
//   internal {
//     type
//   }
//   id
//   bundles {
//     header
//     bundleTitle
//     body {
//       raw
//     }
//     products {
//       subTitle
//       title
//       description {
//         description
//       }
//       badge
//       image {
//         title
//         gatsbyImageData(
//           layout: CONSTRAINED,
//           placeholder: BLURRED
//         )
//       }
//       price
//       ctaLabel
//       type
//       ctaLink
//     }
//   }
// }